#abilities-title {
    display: flex;
    justify-content: center;
    padding: 10px;
}

#abilities-title h1 {
    font-size: 18px;
    font-family: 'Nova Round';
    color: var(--midnight-blue)
}



/* Defining everything inside each ability box */
#abilities-container {
    display: Flex;
    justify-content: space-evenly;
    width: 100%;
    position: relative;
    flex-direction: column;
}

#abilities-container-container {
    height: 100%;
}


.abilityExpanded {
    width: 100%;
    height: max-content;
    padding: 10px;
    max-width: 600px;
}

#ability1,
#ability2 {
    background-color: rgba(var(--midnight-blue-rgb), 0.15);
    border-radius: 6px;
    transition: all ease-in-out 0.3s;
}

#ability1:hover,
#ability2:hover {
    background-color: rgba(var(--midnight-blue-rgb), 0.21);
}


.ability {
    position: relative;
    width: 40%;
    max-width: 100%;
    min-height: 120px;
    height: max-content;
    display: Flex;
    flex-direction: column;
    justify-content: space-between;
}

.ability:hover {
    cursor: pointer;
}

.abilityExpanded:hover {
    cursor: pointer;
}


.icon-title-container {
    /* height: 80%; */
    position: relative;
    display: Flex;
    flex-direction: column;
    justify-content: flex-start;
}

.videogameText {
    padding: 10px;
    color: steelblue;
}

.displayGameCreationLink {
    color: blue
}

#computer-code-icon {
    /* position: absolute; */
    top: 10%;
    left: 5%;
    color: var(--dark-blue);
    padding: 5px;
}

.ability-title {
    text-align: center;
    height: 100%;
    color: var(--dark-blue)
}

.expand-shrink-link-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.expand-shink-link {
    color: var(--midnight-blue);
    font-size: 14px;
    padding-top: 10px;
    text-align: center;
}

.ability-icons {
    display: flex;
    align-items: center;
}


/* ability box */

.ability-content {
    overflow: hidden;
}

.ability-text-container {
    padding: 10px 0px;
}

.ability-text-container p,
.ability-list div ul li,
.ability-list div p {
    color: var(--midnight-blue);
    font-family: "Nova Round"
}

.ability-list div p strong {
    padding: 10px 0px;
    ;
}

.ability-list {
    padding: 10px 0px;
    display: Flex;
    flex-flow: row;
    justify-content: center;
}


.htmlLogo,
.cssLogo {
    width: 30px;
}

.jsLogo {
    width: 60px;
}

/* game section */


.games-List {
    color: var(--midnight-blue);
    font-family: "Nova Round"
}

.games-List {
    display: inline;
}



.games-List a {
    display: inline-block;
    font-family: "Nova Round";
    text-decoration: none;
}


.games-tools-container {
    width: 100%;
}

.game-icon-list {
    width: 100%;
    display: Flex;
    align-items: center;
    justify-content: space-around;
    list-style-type: none;
}

.game-icon-list li img {
    width: 50px;
}

.garagebandLi {
    display: Flex;
    flex-direction: column;
    align-items: center;
}

.garagebandLi span {
    color: black;
}


@media (min-width:768px) {
    /* #abilities {
        padding: 0px 200px;
    } */

    #abilities-title {
        padding: 20px;
    }

    #abilities-title h1 {
        font-size: 24px;
    }

    .ability {
        min-height: 180px;
        justify-content: space-around;
    }

    #abilities-container-container {
        width: 80%;
    }

    #abilities-container {
        width: 100%;
        align-items: center;
    }


    #ability1,
    #ability2 {
        width: 100%;
    }

    .abilityExpanded {
        padding: 40px;
        width: 100%;
    }

    .ability-content {
        line-height: 1.6;
    }

    /* #ability1,
    #ability2 {} */

}


@media(min-width:992px) {

    /* #abilities {
        padding-bottom: 60px;
    } */

    .ability {
        min-height: 220px;
        justify-content: space-around;
    }
}