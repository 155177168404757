.active-background {
    background-color: rgb(170, 173, 172);
}

body {
    min-height: 100vh;
}

#root,
#app,
#generalContainer,
#header-body-container {
    min-height: 100vh;
}


.error-link-home {
    text-decoration: none;
    color: black;
    background-color: rgb(223, 221, 218);
    padding: 3px;
    border-radius: 3px;
}

#error-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#error-nav>* {
    margin-top: 12px;
}

.error-link-home:hover {
    cursor: pointer;
}

.error-link-home span,
.error-link-home em {
    color: blue;
}


#error-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
}

#error-text-container {
    padding: 30px;
    width: 300px;
    background-color: rgb(170, 173, 172);
    display: flex;
    justify-content: center;
    align-items: center;
}

#error-img {
    max-width: 300px;
}

#error-title {
    font-size: 30px;
}

#error-information {
    width: 100%;
    display: Flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
}

#error-nav {
    padding: 30px;
}

#copyright-container-error-page {
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
}

#copyright-container-error-page p {
    color: whitesmoke
}

@media(min-width:768px) {
    img {
        max-width: 400px;
    }
}


@media(min-width:1200px) {
    img {
        max-width: 600px;
    }

    #error-title {
        font-size: 40px;
    }
}