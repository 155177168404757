/* .projects-general-container {

} */

#projects {
    /* background-color: var(--midnight-blue); */
    /* margin: 10px 0 0 0; */
    width: 100%;
    z-index: 1;
}

.loadingFailed {
    text-align: center;
}

.project-title-container-mobile {
    display: Flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.project-title-container-mobile em {
    color: white;
}

/* .project-information-container {
} */

.projects-title-mobile::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: -50%;
    width: 200%;
    height: 100%;
    /* background-color: var(--baby-blue); */
    background: linear-gradient(-90deg, white, var(--baby-blue), white);
    z-index: -1;
}


.projects-title-mobile {
    font-size: 22px;
    font-family: 'Nova Round';
    color: var(--baby-blue);
    padding: 10px;
    position: relative;
    margin: 20px 0px 5px 0px;
}

.projects-title-mobile span {
    font-size: 22px;
    font-family: 'Nova Round';
    color: var(--midnight-blue);
}

.projects-container-mobile {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    /* background: linear-gradient(270deg, #202428 50%, #fbe4f6 50%); */
    background: linear-gradient(270deg, #202428 50%, #202428 50%);
    background: linear-gradient(270deg, #202428 50%, #a2a2a2 50%);
    background: linear-gradient(270deg, #202428 50%, #366a9a 50%);
    background: linear-gradient(270deg, var(--baby-blue) 50%, #366a9a 50%);
}

.projects-container-mobile>* {
    margin: 30px 0px;
}

.projects-container {
    /*  THIS SECTION IS ONLY FOR 768 ++ */
    display: none;
}

#projects-miniatures-container {
    display: none;
}

/* individual project */
.project {
    display: Flex;
    /* width: 100%; */
    height: max-content;
    padding: 30px 0px;
    padding: 20px;
    background-color: var(--baby-blue);
}

.project-reverse {
    flex-direction: row-reverse;
    padding: 20px;
    background-color: white;
    background-color: #202428;
    background-color: #a2a2a2;
    background-color: #366a9a;
}

.project-title {
    font-size: 18px;
    color: var(--dark-blue)
}

.projects-container-mobile div p {
    height: max-content
}

.project-image-container {
    max-width: 350px;
    height: auto;
    z-index: 2;
    position: relative;
}

.project-image {
    height: auto;
    width: 100%;
}

/* .image-code-absolute {
    position: absolute;
    bottom: 0;
} */

.project-text-container {
    display: Flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    padding: 0px 10px 5px 10px;
    position: relative;
    overflow: hidden;
}

.project-description {
    color: var(--midnight-blue);
}

.inactiveProject {
    display: none;
}


/* code button */
.project-code-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3px;
}

.project-code-container a {
    width: 100%;
}

.no-projects-found {
    display: Flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}



.project-site-link {
    width: 60%;
    /* background-color: rgba(var(--midnight-blue-rgb), 0.29); */
    background-color: var(--midnight-blue);
    border-radius: 6px;
    display: Flex;
    justify-content: center;
    text-align: center;
}

.project-site-link a {
    color: var(--baby-blue);
    text-decoration: none;
    /* width: 100%; */
}


.project-code-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    /* background-color: var(--midnight-blue); */
    background-color: var(--dark-blue);
    color: var(--baby-blue);
    text-decoration: none;
    padding: 3px;
}


.project-code-link-right {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    /* background-color: var(--midnight-blue); */
    background-color: var(--dark-blue);
    color: var(--baby-blue);
    text-decoration: none;
    padding: 3px;
    /* border-radius: 6px; */
}

.project-code-link-left {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    background-color: #202428;
    color: var(--baby-blue);
    text-decoration: none;
    padding: 3px;
    /* border-radius: 6px; */
}


.github-a {
    width: 40%;
    height: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.code-icon {
    width: 20%;
    color: var(--baby-blue);
}

.projects-general-container {
    position: relative;
}

.fetching-projects {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}


.miniatures-container {
    display: none;
}


.miniatures-filter {
    display: none;
}

.selected-filter-style {
    background: rgb(74, 73, 82) !important;
}

@media(min-width: 768px) {
    .miniatures-container {
        /* background: linear-gradient(to bottom, #202428,
                white 50%);
        background: linear-gradient(90deg, #202428 50%, #202428 50%);
        background: linear-gradient(90deg, antiquewhite 75%, #202428 30%); */
        background: linear-gradient(270deg, #366a9a 60%, #366a9a 60%);
    }

    /* .project-code-link {
        transform: scale(0.5);
    } */

    .project-title-container-mobile {
        display: none;
    }

    .project-title-container {
        display: flex;
        justify-content: center;
        padding: 10px;
        background-color: transparent !important;
    }


    .project-title-container:hover {
        scale: none !important;
        cursor: default !important;
    }

    #projects {
        width: 100%;
    }


    #projects-general-container {

        /* background: linear-gradient(to right, #051f3a,
                white 30%);
        background-color: #869db5; */
        background: white;

    }

    .image-information {
        /* background-color: #202428;
        background: linear-gradient(270deg, #202428 50%, #c6c6c6 50%);
        background: linear-gradient(270deg, #202428 50%, #fbe4f6 50%);
        background: linear-gradient(270deg, #202428 50%, antiquewhite 50%); */
        padding: 20px 0px;
    }


    .miniatures-filter {
        width: 100%;
        display: block;
        height: max-content;
        /* background: linear-gradient(90deg, var(--baby-blue) 50%, #366a9a 50%); */
        background: linear-gradient(90deg, rgba(var(--midnight-blue-rgb), 0.15) 50%, #366a9a 50%);
        /* background: var(--baby-blue); */
        display: Flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: center; */
    }

    .miniatures-filter div:nth-child(1) {
        width: 50%;
        text-align: end;
    }

    .miniatures-filter div:nth-child(1) p {
        font-size: 22px;
        color: var(--dark-blue);
        padding: 0 20px;
    }


    .miniatures-filter>* {
        padding: 30px;
    }

    .miniatures-filter-nav {
        display: flex;
        justify-content: space-between;
        width: 400px;
    }

    .miniatures-filter-nav p {
        background: var(--dark-blue);
        padding: 10px;
        border-radius: 9px;
        color: white;
    }

    .miniatures-filter-nav>*:hover {
        cursor: pointer;
        transform: scale(1.05);
    }


    .projects-container {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* background: linear-gradient(90deg, var(--baby-blue) 30%, var(--baby-blue) 30%); */
        background: rgba(var(--midnight-blue-rgb), 0.15);
        /* padding: 20px; */
    }





    .image-miniature-container {
        /* width: 50%; */
        padding: 0 20px 20px 20px;
    }

    .image-miniature-container div {
        border-radius: 30px;
    }

    .text-links-container {
        width: 50%;
        height: 350px;
        display: flex;
        flex-direction: column;
        padding: 0 20px 0 0;
    }



    .projects-container-mobile {
        display: none;
    }

    .projects-container-mobile>:not(:nth-child(1)) {
        display: none;
    }

    .project-image-container {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #projects-miniatures-container {
        display: flex;
        height: auto;
        padding: 80px 0px;
        width: 100%;
    }

    .miniatures-container {
        padding: 40px 10px 30px 10px;
        width: 100%;
        /* display: flex;
        flex-flow: row wrap;
        justify-content: space-around; */
        background-color: var(--baby-blue);
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 30px;
        background-color: white;
        margin: 0 auto;
    }

    .miniatures-container div {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 9px;
        /* background-color: rgb(70, 176, 205); */
        max-width: 250px;

    }

    .miniatures-container div:hover {
        scale: 1.03;
        cursor: pointer;
    }

    .miniature-name {
        color: var(--dark-blue);
        font-size: 18px;
        width: 100%;
        padding: 12px;
        background-color: rgb(255, 255, 255);
        border-radius: 0px 0px 9px 0px;
    }

    .miniatureImg {
        width: 140px;
        transition: 0.3s ease-in-out;
        border-radius: 9px 9px 0 0;
    }


    .selectedMiniature-container {
        width: 100%;
        display: Flex;
        height: 350px;
        max-width: 450px;
        justify-content: space-between;
        background-position: center;
        background-repeat: none;
        background-size: contain;
        margin: 0 auto;
        /* transform: scale(0.77); */
        image-rendering: crisp-edges;
        image-rendering: -moz-crisp-edges;
        /* Firefox */
        image-rendering: -o-crisp-edges;
        /* Opera */
        image-rendering: -webkit-optimize-contrast;
        /* Webkit (non-standard naming)*/
        -ms-interpolation-mode: nearest-neighbor;
        /* IE (non-standard property) */
    }

    .selectedMiniFont {
        font-weight: 500;
        font-size: 16px;
        padding: 12px;
        color: white;
        background-color: rgb(34, 38, 88);
        z-index: 3;
        width: 100%;
        border-radius: 0px 0px 9px 0px;
    }



    .miniatureImg {
        width: 100%;
    }


    .project-names-container {
        width: 30%;
        padding: 10px;
        height: 100%;
    }

    .project-ul-list {
        text-decoration: none;
        list-style-type: none;
        overflow-y: scroll;
        height: 80%;
    }


    .project-ul-list>li {
        cursor: pointer;
        margin-top: 10px;
        padding: 3px 0px;
        border: 2px solid transparent;
    }


    .special-title {
        color: var(--baby-blue) !important;
    }

    .projects-title {
        margin-top: 20px;
        font-size: 26px;
        font-family: 'Nova Round';
        /* padding: 10px; */
        position: relative;
        margin: 20px 0px 5px 0px;
        padding: 20px;
        color: var(--dark-blue);
        text-align: center;
        /* background: #366a9a; */
        border-radius: 60%;

    }

    .project-information-container {
        padding: 0 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        background-color: white;
    }

    .project-topics {

        display: flex;
        flex-direction: row wrap;
        justify-content: center;
        align-items: center;
        min-height: max-content;
    }

    .topics-title {
        color: var(--midnight-blue);
        padding: 10px 10px 10px 0px;
    }


    .topic {
        display: inline;
        padding: 5px;
        /* margin-right: 9px; */
        margin-right: 5px;
        border-radius: 6px;
        background-color: var(--baby-blue);
        color: var(--midnight-blue)
    }

    .load-more-projects {
        text-align: center;
        height: 100px;
        display: Flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: var(--blue-gray);
        display: none;
    }

    .load-more-projects a {
        background-color: var(--baby-blue);
        padding: 15px;
        text-decoration: none;
        border-radius: 3px;
    }

    .dark-color {
        color: var(--dark-blue);
    }

    .selectedMiniature-title {
        /*width: 100%;*/
        display: flex;
        justify-content: center;
        color: var(--dark-blue);
        font-size: 1.2rem;
    }

    .image-information {
        display: flex;
        justify-content: center;
    }

    .project-code-container a {
        width: 200px;
    }


}


@media (min-width:992px) {
    .miniatures-container {
        grid-template-columns: repeat(4, 1fr);
    }

    .miniatures-container div {
        max-width: 300px;
    }
}


@media(min-width:1200px) {

    #banner-container {
        padding: 0 100px;
    }



    .miniatures-container {
        grid-template-columns: repeat(5, 1fr);
    }
}