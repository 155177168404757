#about-projects {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 500px; */
    min-height: 400px;
    padding-top: 45px;
}



#about-projects-container {
    /* padding: 20px; */
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h4 {
    font-size: 22px;
    padding: 3px 0px;
    color: var(--dark-blue);
    font-weight: 500;
}

.about-projects-bg {
    padding: 30px;
    position: absolute;
    background-image: url('./../Images/snowytrees.avif');
    background-image: url('./../Images/bluewall.jpg');
    background-image: url('./../Images/cardboardSnowPinetree.jpeg');
    background-image: url('./../Images/snowytrees.avif');
    background-image: url('./../Images/ballena-imagen3d.webp');
    background-image: url('./../Images/blurrytrees.jpeg');
    background-image: url('./../Images/boardtrees2.jpeg');
    background-image: url('./../Images/carboardTurtle.jpeg');
    background-image: url('./../Images/cardbordSea.jpeg');
    background-image: url('./../Images/cardboardPalmTree.jpeg');
    background-image: url('./../Images/blueClouds.jpeg');
    background-image: url('./../Images/wavesR.png');
    background-position: bottom -50px right 0px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    z-index: 0;
}

.about-projects-bg h4 {
    padding: 60px 0px 10px 0px;
    color: var(--dark-blue)
}

#about-projects-paragraph-container {
    padding: 20px 30px 60px 30px;
    line-height: 2;
    /* background: rgba(0, 0, 0, 0.3); */
}

/* #about-projects-paragraph-container p {
    color: rgb(255, 255, 255);
} */

@media(min-width:768px) {
    #about-projects-container h4 {
        font-size: 24px;
    }


}