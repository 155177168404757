#general-container {
    width: 100%;
    min-height: max-content;
    /* height:  ; */
    display: flex;
    align-items: center;
    flex-flow: column;
    background: white;
}

#abilities {
    width: 100%;
    padding: 40px 20px;
    padding-bottom: 40px;
    min-height: 800px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* section {
    margin: 20px 0px;
} */


@media (min-width: 768px) {
    #projects {
        padding: 0px;
    }

}

/* 
@media (min-width:1200px) {
    section {
        margin: 50px 0px;
    }
} */