#footer-container {
    background-color: #F4F5F6;
    min-height: 300px;
    max-height: auto;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#footer-container div {
    padding: 10px;
}

a:hover {
    cursor: pointer;
    transform: scale(1.05);
}

#footer-title-container {
    background-color: var(--dark-blue);
    padding: 20px;
    width: 160px;
    /* margin-top: 20px; */
    border-radius: 64px 50px 50px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer-title {
    color: var(--baby-blue);
    font-size: 18px;
}


#footer-square-container {
    max-width: 300px;
    border: 2px solid var(--dark-blue);
    position: relative;
    min-height: 100px;
    display: flex;
    align-items: center;
    padding: 5px;
}

#footer-square-text {
    color: var(--midnight-blue);
    font-family: 'Nova Round';
    padding: 15px;
}


#footer-square-mail-container {
    border: 2px solid var(--dark-blue);
    background-color: #F4F5F6;
    width: max-content;
    padding: 3px;
    position: absolute;
    right: -10px;
    top: 40%;
}

#footer-square-mail-container a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#footer-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#footer-links a {
    padding: 10px;
    text-decoration: none;
    color: var(--blue-gray);
}

#footer-external-links-section {
    width: 100%;
}

#footer-external-links {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

#footer-external-links>* {
    color: var(--dark-blue);
}


#footer-external-links a {
    text-decoration: none;
}

#copyright-text {
    color: #9F8E8E;
}

#copyright-text span {
    font-weight: 500;
}



@media (min-width:768px) {
    #footer-external-links {
        justify-content: center;
    }

    #footer-square-container {
        max-width: 400px;
    }

    #footer-external-links a {
        padding: 0 20px;
    }

    #footer-container {
        min-height: 600px;
    }
}

@media (min-width:992px) {
    #footer-links-section {
        width: 100%;
    }

    #footer-links {
        width: 100%;
        flex-direction: row;
    }

    #footer-links a {
        padding: 20px 40px;
    }
}

@media (min-width:1200px) {}