#about-me-text-container {
    padding: 60px;
}

.about-title {
    font-size: 24px;
    font-family: 'asap';
    float: left;
    padding: 20px;
}

.about-me-content {
    padding: 20px;
    background: white;
    /* background-color: aliceblue; */
}

/* #header{
    height:45px
} */

/* .first-paragraph-container {
    background-color: rgb(106, 162, 210);
} */

.programming-languages {
    background-color: rgb(106, 162, 210);
}

/* .first-paragraph {
    padding: 9px;
} */

.second-paragraph {
    float: right;
}

.about-me-img {
    /* max-width: 200px; */
    max-width: 140px;
    float: right;
}


@media(min-width:992px) {
    .about-me-content {
        padding: 100px 200px;
    }
}

@media(min-width:1200px) {
    .about-me-content {
        padding: 80px 300px;
    }
}

@media(min-width:1500px) {
    .about-me-content {
        padding: 80px 400px;
    }
}