:root {
    --midnight-blue: #41729F;
    --midnight-blue-rgb: 65, 114, 159;
    --blue-gray: #5885AF;
    --dark-blue: #274472;
    --baby-blue: #C3E0E5;
    --baby-blue-rgb: 195, 224, 229;
}


#header {
    min-height: max-content;
    width: 100%;
    margin-bottom: 30px;
    min-height: 45px;
    height: 500px;
    height: max-content;
    position: relative;

    /* height: 50vh; */
}

.fetching-language {
    display: Flex;
    position: absolute;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    text-align: start;
    width: 100%;
    background: white;
    top: 50px;
}


#all-banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#logo-menu {
    width: 100%;
    height: 45px;

    background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: saturate(180%) blur(10px);
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    /* overflow: hidden; */
    position: fixed;
    z-index: 10;
}

/*A logo menu sticky header */

#logo-menu {
    transition: 1s ease !important;
}


.next-section-icon-white {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 120px;
    /* right: 50%; */
}

.next-section-icon-white:hover {
    cursor: pointer;
}


/* navbar configuration */
#navbar-container-id {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 45px;
    right: 0;
    width: 60%;
    z-index: 5;
}

.navbar-container {
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

#title h1 {
    width: 100%;
    font-family: 'Asap';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
}

.see-through-title {
    padding: 20px;
    color: rgba(255, 255, 255, 1);
    -webkit-text-stroke-color: rgba(255, 255, 255, 1);
    -webkit-text-stroke-width: 3px;
    /* mix-blend-mode: difference; */
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.5);
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
    letter-spacing: 2px;
}


#overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* pointer-events: auto; */
    z-index: -1;
    /* or a higher positive value */
}


#navbar {
    padding-top: 80px;
    height: 300px;
    height: 100vh;
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: flex-start;
    background-size: cover;
    transition: 0.3s ease-in-out;
    background: var(--dark-blue);
}

#navbar li {
    padding: 30px 30px;
    list-style-type: none;
    color: white;
    /* padding: 10px; */
}

#navbar li a {
    font-size: 18px;
    text-decoration: none;
    color: var(--blue-gray);
    color: white;
    font-family: 'Nova Round';
    /* background: rgba(0, 0, 0, 0.5); */
    padding: 10px;
}


/* logo */
#logo {
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#logo a {
    width: 100%;
    /* height: 100%; */
    padding-left: 10px;
    font-size: 20px;
    font-family: 'Nova Round';
    text-decoration: none;
    color: white;
    /* border-right: 1px solid rgb(255, 255, 255);
    border-left: 1px solid rgb(255, 255, 255);
    border-radius: 30%; */
    z-index: 100;
}




/* hamburguer menu */
#hamburguer-menu {
    height: 100%;
    width: 15%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-right: 10px;
    z-index: 100;
}


#hamburguer-menu div {
    margin: 0;
    position: relative;
    width: 20px;
    height: 2px;
    border-radius: 3px;
    background-color: white;
    margin-top: 4px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    /* background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: saturate(180%) blur(10px);
    -webkit-backdrop-filter: saturate(180%) blur(10px); */
}


#title-container {
    /* padding: 20px 0px; */
    /* background-image: url('https://images.unsplash.com/photo-1560710990-9f5d4197b5a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bW91bnRhaW4lMjBzbm93fGVufDB8fDB8fHww&w=1000&q=80');
    background-image: url('https://images.unsplash.com/photo-1512273222628-4daea6e55abb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bW91bnRhaW4lMjBzbm93fGVufDB8fDB8fHww&w=1000&q=80');
    background-image: url('https://wallpapercave.com/wp/wp4853024.jpg'); */
    /* background-image: url('https://wallpapers.com/images/hd/snow-mountain-aerial-shot-landscape-7qkpfr73upoycad6.jpg');
    background-image: url('../Images/possibleBg.jpeg');
    background-image: url('../Images/possibleBg2.avif');
    background-image: url('../Images/possibleBg3.avif');
    background-image: url('../Images/bgImgPines.png'); */
    background-size: cover;
    background-repeat: no-repeat;
}


.logo-language-container {
    display: Flex
}



/* banner */
#banner-container {
    /* background-color: #366a9a;
    background-color: #7a92a8; */
    position: relative;
    display: flex;
    flex-flow: row wrap;
    min-height: 130px;
    height: 100%;
    /* border-radius: 9px; */
    /* padding-bottom: 20px; */
    /* border-bottom: 3px solid var(--dark-blue); */
    position: relative;
}


#myVideo {
    position: fixed;
    right: 0;
    top: 0;
    min-width: 100%;
    /* max-height: 500px; */
    /* max-height: 100%; */
    z-index: -10;
}


#text-container {
    /* background: rgba(240, 248, 255, 0.4); */
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}



#title,
#subtitle {
    width: 100%;
    padding: 10px 20px;
    text-align: center;
}

#title {
    /* color: var(--dark-blue); */
    color: #04344a;
    font-family: 'Asap';
    /* background: rgba(240, 248, 255, 0.8); */
}

#subtitle {
    /* color: var(--dark-blue); */
    color: black;
    /* background: rgba(240, 248, 255, 0.8); */
    color: #04344a;
    padding: 20px;
}

#subtitle h3 {
    font-family: 'Nova Round';
    font-size: 20px;
}


/* links section */
#links-container {
    height: max-content;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -20px;
    right: 50%;
    display: none;
}

#links-container-container {
    height: auto;
    position: relative;
    height: 80px;
    width: 100%;
}

#portfolio-link-container {
    position: absolute;
    background-color: var(--baby-blue);
    border-radius: 3px;
    top: 80%;
    left: 40%;
    width: max-content;
    padding: 9px;
    margin-left: 3px;
}


#portfolio-link-container a {
    color: var(--dark-blue);
    text-decoration: none;
    padding: 12px;
}


#lets-talk-link-container {
    position: absolute;
    background-color: var(--dark-blue);
    border-radius: 3px;
    top: 80%;
    right: 40%;
    width: max-content;
    padding: 9px;
    margin-right: 3px;

}

#lets-talk-link-container a {
    color: var(--baby-blue);
    text-decoration: none;
    padding: 12px;
}


/* image configuration */
#image-container-container {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.image-container {
    position: absolute;
    display: Flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;

    max-width: 160px;
    height: 100%;
    position: relative;
    /* clip-path: polygon(40% 5%, 70% 10%, 130% 40%, 100% 70%, 100% 100%, 70% 100%, 20% 100%, 1% 70%, 5% 30%, 10% 20%);
    -webkit-clip-path: polygon(40% 5%, 70% 10%, 130% 40%, 100% 70%, 100% 100%, 70% 100%, 20% 100%, 1% 70%, 5% 30%, 10% 20%); */
    border-radius: 50%;
}

.img {
    /* width: 100%; */
    max-width: 120px;
    max-height: 100%;
    /* padding: 10px; */
    /* height: max-content; */
    /* position: absolute; */
    /* bottom: -45%; */
}


#image-section {
    position: absolute;
    bottom: 0;
    /* background-image: url('./../Images/profilePhoto.png'); */
    /* background-size: cover; */
    /* background-repeat: no-repeat;
    background-position: top left 20px; */
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 110px;
    height: 100%;
}



/* hamburguer menu interaction */
.openMenuTop {
    transform: rotate(-45deg);
    /* margin-top: 25px; */
    top: 6px;

}

.openMenuMiddle {
    transform: rotate(45deg);
    margin-top: -7px;
}

.openMenuBottom {
    opacity: 0;
    transform: rotate(45deg);
}


.visibleNavbar {
    height: 100%;
    visibility: initial;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}



@media(max-width:290px) {
    .img {
        bottom: -20%;
    }
}

@media(min-width: 768px) {

    .next-section-icon-white {
        bottom: 80px;
        /* right: 50%; */
    }

    #overlay {
        display: none;
    }

    #navbar li {
        padding: 0px 30px;
    }

    #banner-container {
        height: 200px;
        width: 100%;
    }

    #all-banner-container {
        width: 100%;
    }

    #header {
        display: flex;
        flex-flow: row wrap;
    }

    #lets-talk-link,
    #portfolio-link {
        display: none;
    }

    #portfolio-link-container a,
    #lets-talk-link-container a {
        padding: 20px;
    }

    #logo {
        width: auto;
        transition: 0.2s ease-in-out;
    }

    #logo {
        /* width: 100%; */
        text-align: center;
    }

    #logo:hover {
        transform: scale(1.03);
    }

    /* #header {
        background: #366a9a;
    } */



    #portfolio-link-container:hover {
        transform: rotate(5deg)
    }

    #lets-talk-link-container:hover {
        transform: rotate(-5deg)
    }


    /* 
    #logo-menu {
        display: inline-block;
        width: auto;
  
        display: flex;
        justify-content: flex-start;
    } */



    #hamburguer-menu {
        display: none;
    }

    .navbar-container {
        visibility: initial;
        height: auto;
        opacity: 1;
        display: inline-flex;
        width: auto;
        justify-content: flex-end;

    }

    #navbar-container-id {
        flex-grow: 1;
        /* apply some absolute pos fix*/
        width: 75%;
        height: 100%;
        top: 0px;
        overflow: hidden;
    }



    #navbar {
        padding-top: 0px;
        justify-content: space-evenly;
        width: 100%;
        /* background: #f0efef; */
        border-radius: 0px;
        height: 100%;
        align-items: center;
        flex-direction: row;
        background: none;
        /* background: #f0f8ff; */
    }

    #navbar li {
        cursor: pointer;
        transition: 0.1s ease-in-out;
        padding: 0 30px;
    }

    #navbar li a {
        color: white;
        width: 100%;
    }

    #navbar li:hover {
        transform: scale(1.05);
    }


    #title h1 {
        width: auto;
    }


    /* image configuration */
    #image-container-container {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        position: relative;
    }

    .image-container {
        position: absolute;
        display: Flex;
        justify-content: flex-end;
        align-items: flex-end;
        /* top: 30%; */
        right: 20%;
        /* width: 100%; */
        max-width: 160px;
        height: auto;
        position: relative;
        /* clip-path: polygon(40% 5%, 70% 10%, 130% 40%, 100% 70%, 100% 100%, 70% 100%, 20% 100%, 1% 70%, 5% 30%, 10% 20%);
        -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
    }

    .img {
        max-width: 180px;
        /* max-width: 300px; */
        max-height: 200px;
        position: absolute;
        bottom: -50%;
        /* left: 5%; */
    }

    #text-container {
        width: 400px;
    }

}


@media(min-width:992px) {
    #text-container {
        justify-content: center;
    }
}

@media(min-width:1200px) {

    #header {
        margin: 0;
    }

    /* #all-banner-container {
        padding: 0 100px;
    } */

    /* #navbar-container-id {
        padding: 0 100px;
    } */

    #navbar {
        width: auto;
    }

    /* #banner-container {
        height: 220px;
        padding: 0 100px;
    } */

    /* #title,
    #subtitle {
        align-items: center;
        justify-content: center;
    } */

    /* #title-container {
        padding: 0 100px;
        background-color: grey !important;
    } */
}