.cookie-notice {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    z-index: 100;
}

.cookie-notice button {
    background-color: #fff;
    color: #333;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    margin: 0 9px;
}