#about-website-container {
    padding: 60px 10px;
    text-align: center;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

#about-website {
    display: Flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
}

#about-website div {
    padding-top: 30px;
}

#about-website-title {
    font-family: 'Nova Round';
    font-size: 26px;
    /* background: linear-gradient(to left, black 20%, #366a9a, white); */
    background-image: url('https://wallpapers.com/images/hd/snow-mountain-aerial-shot-landscape-7qkpfr73upoycad6.jpg');
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /* color: #366a9a; */
    /* color: #41ffca;
    color: black; */
}


#about-website-layout-navbar-container h6 {
    padding: 6px;
    color: white;
    font-size: 20px;
    border-bottom: 8px double white;
    width: 50%;
}

#about-website-layout-navbar-container p {
    color: white;
    padding: 6px;
}

#about-website-links {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 10px;
    row-gap: 30px;
}

#about-website-links>* {
    background-color: #ffffff;
    border-radius: 9px;
    display: flex;
    width: 200px;
    min-height: 50px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    color: #366a9a;
    width: 160px;
}

#about-website-links p {
    color: #366a9a;
}

#about-website-layout-navbar-container {
    background-color: var(--midnight-blue);
    padding: 10px;
}

@media(min-width:768px) {

    .about-website-container-container {
        display: flex;
        min-height: 800px;
    }

    #about-website {
        min-height: 40vh;
        padding: 40px;
    }

    #about-website>* {
        line-height: 2;

    }

    #about-website-title {
        font-size: 32px;
    }

    #about-website-layout-navbar-container {
        padding: 50px 100px;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
    }

    #about-website-layout-navbar-container div {
        text-align: center;
    }

    #about-website-layout-navbar-container div h6 {
        width: 100%;
    }

}

@media(min-width:992px) {

    .about-website-container-container {
        padding: 20px 20px;
    }

    #about-website-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #about-website-links a {
        width: 400px;
    }
}