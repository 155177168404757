* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'pridi', Arial,
    sans-serif;
  font-size: 16px;
}



#app {
  /* width: 100%; */
  height: 100%;
}


.adjust-content-for-navbar {
  /* padding-right: 95px; */
  filter: blur(10px);
}


.stop-scroll {
  overflow: hidden;
}


#socialLinks-Container {
  position: absolute;
  left: 10px;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.socials-container {
  width: max-content;
  padding: 5px;
  max-width: 80px;
  height: 100px;
  border: 3px solid white;
  border-radius: 6px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--midnight-blue);
}

hr {
  background-color: var(--midnight-blue);
  visibility: hidden;
  opacity: 0;
  width: 0px;
  transition: 0.5s ease-in-out;
}

.social-icon {
  color: white;
}

.social-icon-footer {
  color: var(--midnight-blue);
}

.social-icon:hover {
  transform: scale(1.1);
}

#generalContainer {
  display: flex;
  /* align-items: center; */
  flex-flow: column;
  position: relative;
  width: auto;
  /* border: 5px solid var(--midnight-blue); */
}

/* social links string  */



.showSection {
  display: initial;
}

.hideSection {
  display: none;
}





/* styles for dynamic manipulation */

.displayElement {
  display: none;
}

.noDisplay {
  display: none;
}

.inactive-background {
  background-color: white;
}

.height100vh {
  min-height: 100vh;
}


@media(min-width:768px) {
  #header-body-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #main-content {
    flex: 1 0 auto;
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: 24px;
  }

  .adjust-content-for-navbar {
    /* padding-right: 95px; */
    filter: blur(0px);
  }
}

@media(min-width:992px) {
  /* #header-body-container {
    padding: 40px 40px 0px 40px;
  } */

  /* #socialLinks-Container {
   display: initial;
    visibility: initial;
    opacity: 1;
  } */

  .socials-container {
    padding: 6px;
  }

  hr {
    visibility: inherit;
    opacity: 1;
    width: 5px;
    z-index: -10;
  }
}

@media(min-width:1200px) {


  .socials-container {
    padding: 9px;
  }

  #socialLinks-Container {
    z-index: 10;
  }

  #main-content {
    background-color: #F4F5F6;
  }

  #general-container {
    background-color: white;
  }
}