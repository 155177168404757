.language-switcher {
    display: Flex;
    margin: 0 10px;
    padding: 10px 10px;
    justify-content: center;
}

.language-switcher .active {
    font-weight: 500;
    background-color: var(--dark-blue);
    background-color: rgb(114, 146, 170);
    background-color: rgba(240, 248, 255, 0.3);
    /* padding-top: 20px;
    padding-bottom: 10px; */
}

.language-switcher p {
    display: flex;
    align-items: center;
    color: #1c1c3f;
}

button {
    font-size: 18px;
    background: 0;
    border: 0;
    padding: 0 5px;
    color: aliceblue;
    transition: 0.5s;
    width: max-content;
}

button:hover {
    cursor: pointer;
}

@media (min-width:768px) {
    .language-switcher {
        margin: 0 20px;
        padding: 0 20px;
        max-height: 100%;
    }
}