#technical-proficiency {
    min-height: 450px;
    height: max-content;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 600px;
}

#technical-proficiency-container {
    /* margin: 20px 0px; */
    background-color: var(--dark-blue);
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.technical-skill {
    text-align: center;
    padding: 20px;
    /* margin: 10px 0px; */
}

.technical-skill h6 {
    font-size: 20px;
    color: var(--baby-blue);
    font-weight: 400;
    -webkit-text-stroke: 0.5px;
}

.technical-skill em {
    font-size: 14px;
    color: var(--baby-blue);
}

/* .background1 {
    background: var(--baby-blue);
}

.background2 {
    background: var(--dark-blue)
} */

/* .background1 p {
    color: var(--dark-blue);
}

.background2 p {
    color: var(--baby-blue)
} */

@media (min-width:768px) {
    .technical-skill h6 {
        font-size: 24px;
    }
}